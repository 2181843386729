@import '../../../shared/styles/index.scss';

.conclusion__rate {
    &>span {
        font-weight: 600;
        font-size: .8rem;
        display: inline-block;
        text-align: start;
        display: inline-block;
        width: 100%;
    }

    .rate__content {
        border: 1px solid $main-color;
        color: $main-color;
        border-radius: $border-radius;
        padding: .4rem .4rem .4rem .8rem;
        font-weight: 600;
        font-size: .8rem;
        flex: .5;
        text-align: start;
    }

    span {
        &:not(:last-child) {
            margin-right: .5rem;
        }
    }
}
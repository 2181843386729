@import './StepOneStyles/index.scss';
@import './StepTwoStyles/index.scss';
@import './StepThreeStyles/index.scss';
@import './StepFourStyles/index.scss';

@import '../../../shared/styles/index.scss';

.kyb {
    margin: 1rem auto 0 !important;
    min-height: 600px;
    max-height: 100vh;

    .container-heading {
        margin-bottom: 1rem;
    }

    .kyb-form {
        background: #fff;
        padding: .8rem;

        .kyb-heading {
            p {
                font-size: .9rem;
                font-weight: 600;
                text-align: start;
                color: #323435;
            }
        }

        .steps-indicator {
            position: relative;
            margin: 2rem 0;
            padding: 1rem 0;
            border-bottom: 2px solid #EAEAEA;
            @include setFlex($align: center, $justify: space-between);

            &.responsive-md {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 100%;
                    height: 2px;
                    background: #EAEAEA;
                }
            }

            &>div {
                padding: .25rem .8rem;
                border-radius: $border-radius;
                font-size: 1rem;
                font-weight: 600;
                z-index: 2;

                // background-color: #EAEAEA;
                span {
                    &:first-child {
                        color: #323435;
                        padding: .25rem .8rem;
                        margin-right: .5rem;
                        border-radius: $border-radius;
                        background-color: #EAEAEA;
                    }

                    &:last-child {
                        color: #323435;
                    }
                }

                &.active {
                    span {
                        &:first-child {
                            color: #fff;
                            background-color: $main-color;
                        }

                        &:last-child:not(.step-num) {
                            color: $main-color;
                        }
                    }
                }
            }
        }

        label {
            white-space: nowrap;
            width: 100%;
        }
    }
}

.ant-select-selection-item {
    text-align: start;
    font-weight: 600;
}

.ant-select.ant-select-single.ant-select-show-arrow {
    &::after {
        font-weight: 800;
        color: #000;
    }
}
@import '../../../shared/styles/index.scss';

.kyc {
    form {

        .ant-row.ant-form-item {
            margin: 0;
        }

        .ant-row.ant-form-item {
            width: 100%;
            @include setFlex($justify: flex-start, $direction: column);

            .ant-select-selection-item {
                font-weight: 500;
                text-align: start;
                color: $placeholder-grey-color;
            }

            .ant-row.ant-form-item {
                margin: 0;
            }

            label {
                font-size: .85rem;
                font-weight: 600;
            }

            .ant-col.ant-col-16.ant-form-item-control {
                max-width: 100%;
                width: 100%;
            }

            .ant-col.ant-form-item-control {
                width: 100%;

                &:nth-child(2) {
                    position: relative;

                    a {
                        position: absolute;
                        top: -1.8rem;
                        right: 0;
                        text-decoration: underline;
                        font-weight: 600;
                        color: $main-color;
                    }
                }
            }
        }


        .ant-space.ant-space-vertical,
        .ant-col.ant-col-16.ant-form-item-control,
        .ant-select.ant-select-single.ant-select-show-arrow {
            width: 100%;
        }

        .ant-checkbox-wrapper {
            white-space: nowrap;
            @include setFlex($align: center);

            span {
                &:nth-child(2) {
                    @include setFlex($align: center);

                    button {
                        border: none;
                        outline: none;
                        box-shadow: none;
                        background-color: transparent;
                        color: $main-color;
                        font-weight: 600;
                        padding: 0;
                        margin: 0 .5rem;
                    }
                }
            }
        }


        .ant-upload.ant-upload-select.ant-upload-select-text {
            width: 100%;
            background-color: #0054B81A;
            border-radius: $border-radius;

            button {
                width: 100%;
                background-color: transparent;
                color: $placeholder-grey-color;
                height: 45px;
                font-weight: 800;
                display: grid;
                place-items: center;
                @include setFlex($align: center);

                .anticon.anticon-upload {
                    display: none;
                }

                span {
                    &:last-child {
                        color: $main-color;
                        font-weight: 600;
                        margin-left: .5rem;
                    }
                }
            }
        }
    }
}
@import '../../../shared/styles/index.scss';


.mint__form {
    margin-top: 1rem;
    padding: 1rem;

    .form__col {
        &:first-child {
            margin-right: 1rem;
        }
    }

    .grey {
        background-color: $grey-bg !important;
    }

    form {
        .form__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .input__field {
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                @include setFlex($direction: column);

                label {
                    font-size: .8rem;
                    font-weight: 600;
                }

                input {
                    border: none;
                    outline: none;
                    background-color: #F8F8F8;
                    border: 1px solid #E2E2E2;
                    border-radius: $border-radius;
                    padding: .5rem .8rem;
                    width: 100%;
                    margin-top: .25rem;
                }

                .ant-select.ant-select-single.ant-select-show-arrow {
                    width: 100% !important;
                }

                .ant-select-selection-item {
                    display: inline-block;
                    text-align: start;
                    color: $placeholder-grey-color;
                    font-size: .9rem;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    padding: .25rem .8rem;
                    margin-top: .25rem;
                }
            }
        }
    }

    button {
        margin-right: .5rem;
        padding: .25rem 1.2rem;
        font-weight: 600;
        border-radius: $border-radius;
        background: $main-color;
        color: #fff;
        display: block;
        margin-top: 1rem;
        margin-left: auto;
    }
}
@import '../../../shared/styles/index.scss';

.kyc-warning-box {
    padding: .5rem 2rem;
    margin: .75rem 0;
    text-align: start;
    border-radius: $border-radius;
    color: $warning-color;
    background: $warning-bg;
    border: $warning-border;
    @include setFlex($direction: column);

    h3 {
        font-weight: 800;
        color: $warning-color;
        margin: 0;
        text-align: start;
        margin-left: -.8rem;
        margin-bottom: .5rem;
    }
}
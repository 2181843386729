@import '../../../../shared/styles/index.scss';

.kyb-step-three {
    .kyb-control-persons {
        .container-heading {
            font-weight: 800;
        }

        .kyb-owners-content {
            text-align: start;
        }

        .kyb-owners-intro {
            margin-bottom: 2rem;

            p {
                &:nth-child(2) {
                    margin-top: 1rem;
                }
            }

            button {
                display: block;
                margin: 1rem 0;
                font-weight: 800;
                color: $main-color;
                background: transparent;
                padding: 0;
                border: none;
                border-bottom: 2px solid $main-color;
            }
        }

        .kyb-adding-owner {
            button {
                padding: .5rem;
                height: fit-content;
                max-width: 100%;
                border-radius: $border-radius;

                span {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    white-space: normal;
                }

                &:nth-child(2) {
                    margin: .8rem 0;
                    color: #fff;
                    font-weight: 600;
                    background-color: $main-color;
                }

                &:nth-child(3) {
                    width: 100%;
                    color: $main-color;
                    font-weight: 600;
                    background-color: transparent;
                    border: 1px solid $main-color;
                }
            }
        }

        .kyb-owners-note {
            margin: 1rem 0;
        }
    }

    .add-beneficial-entity-content {
        background-color: #F6F6F6;
        padding: .8rem;
        border-radius: $border-radius;
        border: 1px solid #EBEBEB;

        .container-heading {
            font-size: .9rem;
        }

        .heading {
            padding: .8rem .2rem;
            border-bottom: 1px solid #ECECEC;
            margin-bottom: 1rem;
            @include setFlex($align: center, $justify: space-between);

            .user-info {
                @include setFlex($align: center);

                .avatar {
                    width: 24px;
                    height: 24px;
                    margin-right: .5rem;
                }

                h4 {
                    font-weight: 600;
                }
            }

            .toggle-icon {
                img {
                    width: 16px;
                    object-fit: contain;
                }
            }
        }

        .options {
            width: fit-content;
            margin-left: auto;
            margin-bottom: 1rem;
        }

        button.save {
            color: #fff;
            font-weight: 500;
            width: 100px;
            height: 42px;
            font-size: 1rem;
            background: $main-color;
            border-radius: $border-radius;
        }
    }
}
@import '../../../shared/styles/index.scss';

.transactions__header {
    padding: 1rem .5rem;
    background-color: #fff;
    border-radius: $border-radius;
    @include setFlex($direction: column);

    .top-header {
        width: 100%;
        @include setFlex($justify: space-between, $align: center);
    }

    &__options {
        padding: .5rem;
        background-color: #ECECEC;
        border-radius: $border-radius;

        ul {
            margin-bottom: 0;
            @include setFlex($justify: space-between, $align: center);

            li {
                @include setFlex($justify: center, $align: center);

                padding: .5rem 1rem;
                border-radius: $border-radius;
                cursor: pointer;
                font-weight: 600;
                font-size: .8rem;
                color: #303030;

                &.active {
                    color: #fff;
                    font-weight: 800;
                    background-color: $black-bg;
                }
            }
        }
    }

    // responsive view
    .top-header {
        &.responsive-md {
            @include setFlex($direction: column);

            &>div {
                width: 100%;
            }

            .top {
                margin-bottom: 1.5rem;
                @include setFlex($justify: space-between, $align: center);

                img {
                    width: 35px;
                    object-fit: contain;
                }
            }
        }
    }
}
@import '../../../../shared/styles/index.scss';

.kyb-step-four {
    .kyb-additional-info {
        .account-agreement {
            border-top: 1px solid grey;

            .info__warning {
                margin: .8rem;
                padding: .4rem;
                background: #A881000D;
                border: 1px solid #A881001A;
                @include setFlex($align:center);

                span {
                    &:first-child {
                        align-self: self-start;
                    }
                }

                p {
                    color: $warning-color;
                    margin-bottom: 0;
                    margin-left: .5rem;
                    font-size: .75rem;
                    text-align: start;

                    a {
                        font-weight: 600;
                        color: $warning-color;
                        text-decoration: underline;
                    }
                }
            }
        }

        .ant-form-item-required {
            font-size: .85rem;
        }
    }

    .kyb-step-four-note {
        text-align: start;
        @include setFlex();
    }
}
@import '../../../shared/styles/index.scss';

.infoBx {
    padding: 1rem;

    .infoBx-heading {
        @include setFlex($align: center, $justify: space-between);

        .status {
            color: $green-color;
            font-weight: 600;
            background-color: #269A431A;
            border-radius: 14px;
            padding: .25rem .8rem;

            img {
                width: 14px;
                object-fit: contain;
                margin-right: .25rem;
            }
        }
    }
}

.infoBox {
    flex: 1;
    width: 100%;
    margin: 1rem auto;
    background: #fff;
    box-shadow: $box-shadow;
    border-radius: $border-radius;

    .info__warning {
        margin: .8rem;
        padding: .4rem;
        background: #A881000D;
        border: 1px solid #A881001A;
        @include setFlex($align:center);

        p {
            color: $warning-color;
            margin-bottom: 0;
            margin-left: .5rem;
            font-size: .75rem;

            a {
                font-weight: 600;
                color: $warning-color;
                text-decoration: underline;
            }
        }
    }

    .info__content {
        padding: .5rem 0;

        .info__row {
            padding: .5rem .8rem;
            @include setFlex($justify: space-between, $align: center);
            background-color: #fff;

            &:nth-child(even) {
                background-color: #F3F3F3;
            }

            h4 {
                color: $grey-color;
                font-size: .8rem;
                font-weight: 600;
            }

            p {
                font-weight: 600;
                margin-bottom: 0;
                max-width: 100%;
            }

            &.approved {
                .container {
                    @include setFlex($align: center);

                    p {
                        color: $green-color;
                        margin-left: .3rem;
                    }
                }
            }
        }
    }

    // .uploadedDocs__info {
    //     margin: 2rem 0;

    //     p {
    //         color: $grey-color;
    //         margin-top: 1rem;
    //         font-weight: 600;
    //     }
    // }
}
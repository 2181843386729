@import '../../../shared/styles/index.scss';

.transactions-apply-filter {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 400px;
    background-color: #fff;
    box-shadow: $box-shadow;
    border-radius: $border-radius $border-radius 0 0;
    padding: 1rem;

    .apply-filter-heading {
        @include setFlex($align: center, $justify: space-between);
    }

    &>div {
        text-align: start;

        h3,
        h4,
        h2 {
            font-weight: 800;
            color: #000;
            margin-bottom: .8rem;
        }

        .ant-select.ant-select-single.ant-select-show-arrow {
            width: 100% !important;

            .ant-select-selection-item {
                font-weight: 600;
            }
        }
    }

    .payment-status {
        ul {
            @include setFlex($justify: space-between, $align: center);

            li {
                padding: .25rem;
                font-weight: 600;

                &.selected {
                    background-color: transparent;
                    border: 1px solid $main-color;
                    border-radius: $border-radius;
                    flex: .333;
                    text-align: center;
                    color: #000;
                }
            }
        }

        margin-bottom: 1rem;
    }

    .options {
        @include setFlex($justify: space-between, $align: center);

        button {
            flex: .5;

            &:first-child {
                @include setFlex($align: center, $flex:.5);

                img {
                    margin-right: .8rem;
                }
            }

            &:last-child {
                background-color: $main-color;
            }
        }
    }
}
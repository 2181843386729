// global variables
$main-color: #0054B8;
$light-main-color-bg: rgba(0, 84, 184, .1);
$grey-color:#757575;
$grey-bg: #323435;
$grey-btn: #27292A;
$border: 1px solid #323435;
$warning-color: #C16800;
$warning-bg:#C1680014;
$warning-border: 1px solid #C1680040;
$light-grey:#EBEBEB;
$black-bg:#1A1C1E;
$white-bg:#F2F2F2;
$black-color:#303030;
$red-color:#C81500;
$danger-bg:#CD2C2B;
$success-bg:#269A43;
$main-bg:#ECECEC;
$main-grey-bg:#1A1C1E;
$main-grey-color:#1A1C1E;
$main-grey-border: 1px solid #1A1C1E;
$secondary-grey-color: #323435;
$placeholder-grey-color:#606060;
$green-color:#00A27B;
$border-radius: 5px;
$box-shadow: 3px 6px 10px #C1C1C129;

// global mixins
@mixin setFlex($justify: flex-start, $align: flex-start, $direction: row, $wrap: no-wrap, $flex: 0 1 auto) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-direction: $direction;
    flex-wrap: $wrap;
    flex: $flex;
}

// global styles
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}

body {
    background: #ECECEC;
}

body,
html {

    overflow: hidden;
}

// customized scrollbar styles
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;

}

/* scrollable container with now scrollbar */
.scrollable {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;

    /* Firefox */
    &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }
}

.main-container {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba(0, 0, 0, .8);
    }

    .main {
        // height: 100vh;
        // margin-top: 3.5rem;
        background-color: #ECECEC;
        overflow-x: hidden;
        @include setFlex($direction: column, $justify: flex-start);

        &>section {
            flex: 1;
            margin: .25rem auto 0;
        }

        &>*:not(header) {
            margin-top: 1rem;

            opacity: 0;
            animation: fadeIn .25s ease forwards;

            &>*:first-child {
                margin-top: 0;
                padding-top: 0;

                &>*:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Shared Classes */
.container-fluid {
    // width: 1150px;
    width: 85%;
    margin: auto;
}

.responsive-md {
    &.container {
        width: 92vw;
        margin: auto;

        &:not(.main-header-nav) {
            margin: .8rem auto !important;
            width: 92%;
        }

        &.dashboard {
            width: 100%;
        }

        &>div {
            width: 100%;
        }
    }
}

/* Elements Tags */
ul {
    list-style: none;
    margin: 0;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

/* Input Field */
.input__field {

    label {
        font-size: .8rem;
        font-weight: 600;
    }

    input {
        border: none;
        outline: none;
        background-color: #F8F8F8;
        border: 1px solid #E2E2E2;
        border-radius: $border-radius;
        padding: .5rem .8rem;
        width: 100%;
        margin-top: .25rem;
    }
}

/* Custom classes */

// container heading
.container-heading {
    color: $main-color;
    font-weight: 600;
    margin-bottom: 0rem;
    text-align: start;
}

// form heading
.form-heading {
    h3 {
        color: $main-grey-color;
        font-weight: 800;
        margin-bottom: .5rem;
    }

    p {
        font-size: .85rem;
        font-weight: 600;
        text-align: start;
        color: #505050;
    }
}

// black button
.btn-black {
    background-color: $black-bg;
    color: #fff;
    border-radius: $border-radius;
    padding: .5rem 2.6rem;
    font-weight: 600;

    &.lg {
        width: 140px;
        height: 40px;
    }
}

/* ANTD overriding */

.ant-anchor-ink::before {
    display: none !important;
}

.ant-anchor-wrapper {
    padding-left: 0;
}

.ant-menu.ant-menu-dark,
.ant-layout-sider,
.ant-layout-sider-dark {
    background: $black-bg;
}

.ant-layout-sider,
.ant-layout-sider-dark {
    min-width: 250px !important;
}

.ant-menu.ant-menu-dark {
    padding: .6rem;
}

.ant-menu.ant-menu-dark li {
    height: 60px !important;
    font-weight: 600;
    line-height: 60px;
    padding: 0;
    margin: 0 !important;

    &:not(:first-child) {
        border-top: $border;
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: $grey-bg;
    border-radius: $border-radius;
    box-shadow: 2px 5px 10px #1E1E1E29;
}

.ant-menu-inline .ant-menu-item {
    margin: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.ant-select-selection-placeholder {
    text-align: start;
}

thead tr th.ant-table-cell {
    background-color: $main-bg;
}

// NOTE: THESE UPDATES FOR THE RESPONSIVE VIEW MAY CAUSE SOME CONFLICTS WITH THE MAIN VIEW
.ant-col.ant-form-item-control {
    width: 100%;
    @include setFlex($direction: column);
}

.ant-form-item-control-input {
    width: 100%;
}

.ant-input.ant-input-disabled {
    color: #000;
}

table {
    tbody {
        tr {
            td {
                font-size: .85rem;
                font-weight: 600;
                box-shadow: 3px 6px 10px #C1C1C129;

                a {
                    color: #000;
                }

                &:first-child {
                    &::after {
                        content: '.';
                    }
                }
            }
        }
    }
}
@import './InfoBox.scss';
@import './UploadedDocs.scss';
@import './ProfileAntd.scss';
@import "./ProfileInfoCategs.scss";

@import '../../../shared/styles/index.scss';

.profile {
    margin-top: 1rem !important;
    @include setFlex($direction: column);

    // responsive view
    &.responsive-md {
        .info-categories {
            ul {
                max-width: 96vw;
                overflow-x: auto;
            }
        }
    }
}
@import '../../../shared/styles/index.scss';

.uploadedDocs-info {
    .download-box {
        position: relative;
        padding: .5rem;
        // background-color: #EEEEEE;
        @include setFlex($justify: space-between, $align: center);

        input {
            height: 35px !important;
        }

        button {
            color: #fff;
            height: 40px;
            display: grid;
            place-items: center;
            background-color: $main-color;
            border-radius: $border-radius;
            position: absolute;
            top: -2.5rem;
            right: 0;
            @include setFlex($align: center);

            img {
                margin-right: .5rem;
            }
        }
    }
}
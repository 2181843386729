@import '../../shared/styles/index.scss';

header {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
    padding: 1rem;
    box-shadow: $box-shadow;
    @include setFlex($justify: space-between, $align: center);

    &.block {
        width: 100%;
    }

    .logo {
        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }

    .notifications {
        width: 3.5rem;

        &__icon {
            cursor: pointer;
        }
    }

    .header-menu {
        position: relative;

        .menu-modal {
            background: #fff;
            border-radius: $border-radius;
            position: absolute;
            top: 2.5rem;
            right: 0;
            width: 190px;
            padding: .8rem;
            z-index: 1000;
            box-shadow: 0 3px 6px 4px #211A1A33;
            @include setFlex($direction: column);

            .link {
                color: #000;
                font-weight: 600;
                width: 100%;
                @include setFlex($justify: space-between, $align: center);

                .text {
                    img {
                        width: 14px;
                        object-fit: contain;

                        &.profile-pic {
                            width: 24px;
                        }
                    }

                    span {
                        margin-left: .5rem;
                    }
                }

                &:first-child {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    // responsive view
    &.responsive.md {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 100;
        background: #fff;
        padding: 1rem;
        box-shadow: $box-shadow;
        @include setFlex($justify: space-between, $align: center);
    }
}
@import '../../../shared/styles/index.scss';

.login-form {
    form {
        .ant-row.ant-form-item {
            width: 100%;
            @include setFlex($justify: flex-start, $direction: column);

            .ant-col.ant-form-item-control {
                width: 100%;

                &:nth-child(2) {
                    position: relative;

                    a {
                        position: absolute;
                        top: -1.8rem;
                        right: 0;
                        text-decoration: underline;
                        font-weight: 600;
                        color: $main-color;
                    }
                }
            }
        }

        .ant-row.ant-form-item {
            .ant-col.ant-form-item-label {
                .ant-form-item-required {
                    font-weight: 600;
                    font-size: .8rem;
                    position: relative;

                    &::after {
                        display: none;
                    }
                }
            }

        }

        .ant-form-item-control-input-content {
            width: 100%;
            @include setFlex($direction: column);

            &:last-child {
                margin: auto;
                text-align: center;

                button {
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
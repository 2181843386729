.wire-wrap {

  .note-wrap {
    text-align: left;
  }
}

.wire-header {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  display: block;
  margin-left: 20px;
  margin-bottom: 20px;

  @media (max-width: 850px) {
    line-height: 26px;
    font-size: 20px;
    margin-left: 0;
    color: #2058E5;
    font-weight: 600;
  }
}

.wire-payment-btn-wrap {
  padding-bottom: 50px;

  .ant-btn.primary-button {
    margin-right: 0;
    color: #fff;

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

@import './MintForm.scss';
@import './MintWarning.scss';
@import './MintFormConclusion.scss';

@import '../../../shared/styles/index.scss';

.mint__screen {
    background-color: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin-top: .8rem;
    padding: 0 .5rem;

    .mint-heading {
        @include setFlex($align: center, $justify: space-between);
        border-bottom: 1px solid $grey-color;
    }

    h4 {
        text-align: start;
        padding: .5rem 0;
        font-weight: 600;
        border-bottom: none !important;
    }


    // responsive view
    &.responsive-md {
        h4 {
            color: $main-color;
            font-size: 1.1rem;
        }

        .exchange-info {
            border-radius: 10px;
            color: #000;
            font-weight: 600;
            font-size: 1.1rem;
            background-color: #3030301A;
        }

        &>div {
            max-width: 100%;
        }

        .form__col {
            width: 100%;
        }

        .form__grid {
            max-width: 100vw;
            display: grid;
            grid-template-columns: repeat(1, 1fr);


            input {
                background-color: #EEEEEE !important;
            }
        }
    }
}
@import '../../../shared/styles/index.scss';

.profile {

    .basic-info,
    .kyc-info,
    .wallet-info,
    .uploadedDocs-info {
        .ant-row.ant-form-item {
            width: 100%;
            @include setFlex($justify: flex-start, $direction: column);

            .ant-select-selection-item {
                font-weight: 500;
                text-align: start;
                color: $placeholder-grey-color;
            }

            .ant-row.ant-form-item {
                margin: 0;
            }

            label {
                font-size: .85rem;
                font-weight: 600;
            }

            .ant-col.ant-col-16.ant-form-item-control {
                max-width: 100%;
                width: 100%;
            }

            .ant-col.ant-form-item-control {
                width: 100%;

                &:nth-child(2) {
                    position: relative;

                    a {
                        position: absolute;
                        top: -1.8rem;
                        right: 0;
                        text-decoration: underline;
                        font-weight: 600;
                        color: $main-color;
                    }
                }
            }
        }
    }

    .ant-space.ant-space-vertical,
    .ant-col.ant-col-16.ant-form-item-control,
    .ant-select.ant-select-single.ant-select-show-arrow {
        width: 100%;
    }
}
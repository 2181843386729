.kyb {
    .kyb-form {
        .kyb-second-step-note {
            text-align: start;
            margin: .5rem 0 1rem;

            b {
                font-weight: 800;
            }
        }

        label {
            font-weight: 600;
        }
    }
}
@import '../../../shared/styles/index.scss';

.showcase {
    margin-top: 1rem;

    // responsive md
    &.responsive-md {
        margin: 0;
        @include setFlex($direction: column);

        .showcase__header {
            width: 100%;

            .ant-select.ant-select-single.ant-select-show-arrow {
                width: 150px !important;
            }
        }
    }

    &__header {
        background: #fff;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: .5rem .8rem;
        @include setFlex($justify: space-between, $align: center);

        h4 {
            color: $main-color;
            font-weight: 800;
            margin-bottom: 0;
        }
    }

    &__select {
        background: #fff;
        border: 1px solid #DBDBDB;
        border-radius: 5px;

        .ant-select-selector {
            background: #F1F1F1 !important;
            font-weight: 600;
            border: 1px solid #DFDFDF;
        }

        .ant-select-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ant-select-selection-item {
                text-align: start;
            }

            .ant-select-arrow {
                color: #000;
            }
        }
    }

    &__processes {
        margin-top: 1rem;
        width: 100%;
        @include setFlex($justify: space-between, $align: center);

        &.responsive-md {
            @include setFlex($direction: column);

            .process__card {
                width: 100%;
                margin-bottom: .5rem;
                min-height: 260px;

                .process__content {
                    margin: .5rem 0;
                }
            }
        }

        .process__card {
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            background-color: #fff;
            padding: 1rem;
            min-height: 250px;
            max-height: 260px;
            text-align: start;
            @include setFlex($align: flex-start, $justify:flex-start, $direction: column, $flex: 0.333);

            &:not(:last-child) {
                margin-right: .5rem;
            }

            h4 {
                font-weight: 800;
                margin-top: 1rem;
            }

            .process__content {
                @include setFlex($justify: center, $align: flex-start, $flex:1, $direction: column);

                p {
                    color: #606060;
                }
            }

            .process__options {
                button {
                    color: #fff;
                    display: block;
                    border-radius: $border-radius;
                    padding: .4rem 2.5rem;
                    background: $main-color;
                }
            }

            &:first-child {
                .process__content {
                    p {
                        color: $main-color;
                        @include setFlex($align: center, $direction: column);

                        span {
                            font-size: 1rem;

                            &:first-child {
                                font-size: 1.2rem;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .wallet__stats {
            @include setFlex($align: center);

            &>span {
                font-weight: 600;
                color: #505050;
                font-size: .75rem;
            }

            .percentage {
                span {
                    color: $green-color;
                    margin: 0 .5rem;
                    font-size: .8rem;
                    font-weight: 600;
                }
            }
        }
    }

}
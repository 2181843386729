@import '../../../../shared/styles/index.scss';

.beneficial-ownership {
    text-align: start;

    .beneficial-ownership-heading {
        @include setFlex($align: center, $justify: space-between);
    }

}

// TODO: FIX THE BUTTONS STYLES AND HANDLE CONFLICTS WITH SIDEBAR BUTTONS STYLES
.kyb-modal-options {
    @include setFlex($direction: column);

    .kyb-modal-btn {
        width: 100% !important;

        &:first-child {
            background: $main-color !important;
        }

        &:last-child {
            margin: 1rem 0;
        }
    }
}

.beneficial-ownership-modal {
    .ant-modal-content {
        // max-width: 100vw;
    }
}
@import './KycFormAntd.scss';

@import '../../../shared/styles/index.scss';

.kyc {
    .kyc-form-container {
        width: 100%;
    }

    form {
        padding: 1rem 1rem 2rem;
        min-height: 1160px;
        border-radius: $border-radius;
        background: #fff;
        box-shadow: $box-shadow;

        .kyc-form-heading {
            margin-bottom: 1rem;
            margin-right: auto;
            padding-bottom: .8rem;
            border-bottom: 1px solid #ECECEC;

            h3 {
                color: $main-grey-color;
                text-align: start;
                font-weight: 800;
            }

            p {
                text-align: start;
                margin-bottom: 0;
                color: #505050;
                font-weight: 600;
            }
        }

        .kyc-form {
            h3 {
                padding: .8rem 0;
                border-top: 1px solid #ECECEC;
                border-bottom: 1px solid #ECECEC;
                box-shadow: 3px 6px 10px #C1C1C129;
            }
        }

        button {
            color: #fff;
            background: $main-color;
        }

        .download-box {
            padding: .5rem;
            background-color: #EEEEEE;
            @include setFlex($justify: space-between, $align: center);

            span {
                font-weight: 600;
                font-size: 1rem;
            }

            button {
                height: 35px;
                display: grid;
                place-items: center;
                background-color: $main-color;
                border-radius: $border-radius;
            }
        }
    }

    .ant-checkbox-wrapper {
        white-space: normal !important;
        // display: flex !important;
        // flex-direction: column !important;

        .ant-checkbox.ant-checkbox-checked {
            align-self: flex-start;
        }

        span {
            display: block !important;
        }
    }
}
@import './TransferForm.scss';
@import './TransferShowcase.scss';

@import '../../../shared/styles/index.scss';

.transfer {
    margin-top: .8rem !important;

    &>div {
        margin-bottom: 1rem;
    }

    &>h4 {
        padding-bottom: .5rem;
        text-align: start;
        font-weight: 600;
        border-bottom: 1px solid #E2E2E2;
    }
}
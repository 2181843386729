@import '../../../shared/styles/index.scss';

.profile {
    .info-categories {
        width: 100%;
        margin-right: 1rem;
        background: #fff;
        box-shadow: $box-shadow;
        border-radius: $border-radius;


        ul {
            padding: .5rem;
            width: 100%;
            @include setFlex();

            li {
                padding: .8rem;
                width: 100%;
                white-space: nowrap;
                // background-color: #fff;
                cursor: pointer;
                text-align: start;
                font-weight: 600;
                text-align: center;
                // box-shadow: 3px 6px 10px #AEAEAE29;
                border-top: 3px solid transparent;
                border-radius: $border-radius $border-radius 0 0;
                @include setFlex($align: center, $justify: center);

                img {
                    width: 13px;
                    height: 13px;
                    object-fit: contain;
                    margin-right: .5rem;
                }

                &:not(:last-child) {
                    margin-bottom: .5rem;
                }

                &.active {
                    color: $main-color;
                    font-weight: 700;
                    background-color: rgba(0, 84, 184, .1);
                    border-top: 3px solid $main-color;
                }
            }
        }
    }
}
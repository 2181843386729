@import '../../shared/styles/index.scss';

.main-header-nav {
    width: 96vw;
    border-radius: $border-radius;
    margin: 1.2rem auto 0 !important;
    padding: .5rem;
    background: #1A1C1E;

    nav {

        ul {
            @include setFlex($align: center, $justify: space-between);
            overflow-y: auto;

            li {
                list-style: none;
                white-space: nowrap;

                &:nth-child(2) {
                    width: 120px;
                }

                a {
                    padding: .5rem .8rem;
                    border-radius: $border-radius;
                    @include setFlex($direction: column, $align: center);

                    img {
                        opacity: .6;
                        width: 15px;
                        object-fit: contain;
                    }

                    span {
                        color: #fff;
                        font-size: .8rem;
                        margin-top: .2rem;
                        width: 100%;
                        opacity: .6;
                    }

                    &.active-link {
                        background: #363636;

                        img,
                        span {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
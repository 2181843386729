@import './RecentTransactions.scss';
@import './TransactionsGrid.scss';
@import './TransactionsHeader.scss';
@import './TransactionsSubHeader.scss';
@import './TransactionsApplyFilter.scss';

.transactions {
    margin-top: 0 !important;

    &.responsive-md.container {
        padding: 0 !important;
    }


    .recent__transactions__list.scrollable.responsive-md {
        padding: 0;
    }


}
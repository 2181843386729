@import '../../../shared/styles/index.scss';

.recent__transactions__list {
    padding: 1rem 0;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    table {
        thead.ant-table-thead {
            background: #F1F1F1;

            tr {
                white-space: nowrap;

                th.antd-table-cell {
                    font-size: .9rem;
                    font-weight: 600;
                    background: #080;

                    &:nth-child(4) {
                        text-align: center !important;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: .85rem;
                    font-weight: 600;
                    box-shadow: 3px 6px 10px #C1C1C129;
                }
            }
        }
    }

    // responsive view
    &.responsive-md {
        max-width: 96vw;

        .ant-table-tbody {
            background-color: #fff;

            td {
                white-space: nowrap;
            }
        }

        .ant-spin-nested-loading {
            width: 100%;
        }

        .ant-table {
            overflow-x: auto;

            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
                /* Safari and Chrome */
            }
        }
    }
}
.alert-wrap {
  width: 100%;

  @media (max-width: 850px) {
    padding: 0 16px 10px;
  }

  .ant-alert {
    width: 100%;
    text-align: center;
    padding: 12px 10px 12px;
    background: #FAF9DC;
    border-radius: 6px;
    border: 0;
    font-size: 16px;

    .anticon-info-circle {
      display: none;
    }
  }
}

@import './KybForm.scss';

@import '../../../shared/styles/index.scss';

.kyb {
    .kyb-submit {
        @include setFlex($justify: flex-end);

        button {
            width: 120px;
            padding: 1.2rem !important;
            font-size: 1rem;
            text-align: center;
            border-radius: 4px;
            background: $main-color;
            @include setFlex($align: center, $justify: center);

            &.back-btn {
                color: #000;
                background: #EAEAEA;
                margin-right: 1rem;
            }

            img {
                width: 14px;
                object-fit: contain;
                position: relative;
                left: 100%;
            }
        }
    }
}
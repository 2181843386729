@import '../../../shared/styles/index.scss';

.privacy-policy {
    padding: 1rem;
    border-radius: $border-radius;
    box-shadow: 0px 10px 20px #C4C4C426;

    .privacy-policy-heading {
        padding-bottom: 1rem;
        border-bottom: 1px solid #EBEBEB;
        @include setFlex($justify: space-between, $align: center);

        h3 {
            margin: 0;
        }
    }

    .privacy-policy-content {
        margin-top: 1rem;
        text-align: start;

        .content-box {
            margin-bottom: 1rem;

            h4 {
                font-weight: 900;
                margin-bottom: .5rem;
            }

            p {
                color: #323435;
                font-weight: 500;
            }

        }
    }


}

.ant-modal {

    &.privacy-policy-popup,
    &.terms-of-use-popup {
        // width: 970px;
        max-width: 100vw !important;
        // min-width: 960px;
        height: 700px;
        min-height: 500px;
        max-height: 720px;

        .ant-modal-content {
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow: auto;
            padding: 0;
            max-width: 100vw;

            .ant-modal-body {
                margin: 0;
            }

            .ant-modal-footer {
                text-align: end;
                padding: 1.25rem 0;

                .ant-btn.ant-btn-primary {
                    background-color: $main-color;
                }
            }
        }
    }

    &.terms-of-use-popup {
        .privacy-policy-heading {
            flex-direction: column;

            .heading-top {
                margin-bottom: .5rem;
                width: 100%;
                @include setFlex($justify: space-between, $align: center);
            }

            p {
                text-align: start;
                color: #323435;
                font-weight: 500;
            }
        }

    }
}
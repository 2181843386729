@import '../../../shared/styles/index.scss';

.update-password {
    display: grid;
    place-items: center;
    height: 80vh;

    form {
        padding: 2rem 1rem;
        // width: 600px;
        min-height: 350px;
        max-height: 380px;
        margin: auto;
        border-radius: $border-radius;
        background: #fff;
        box-shadow: $box-shadow;

        .reset-password-form-heading {
            margin-bottom: 1rem;
            margin-right: auto;

            .heading-content {
                h3 {
                    color: $main-grey-color;
                    text-align: start;
                    font-weight: 800;
                }

                p {
                    text-align: start;
                    margin-bottom: 0;
                    color: #505050;
                    font-weight: 500;
                }
            }
        }

        .ant-row.ant-form-item {
            width: 100%;
            @include setFlex($justify: flex-start, $direction: column);

            .ant-col.ant-form-item-control {
                width: 100%;

                &:nth-child(2) {
                    position: relative;

                    a {
                        position: absolute;
                        top: -1.8rem;
                        right: 0;
                        text-decoration: underline;
                        font-weight: 600;
                        color: $main-color;
                    }
                }
            }
        }

        .ant-row.ant-form-item {
            .ant-col.ant-form-item-label {
                .ant-form-item-required {
                    font-weight: 600;
                    font-size: .8rem;
                    position: relative;

                    &::after {
                        display: none;
                    }

                    // &::before {
                    //     position: absolute;
                    //     right: -2rem;
                    //     z-index: 2;
                    // }
                }
            }

        }

        .ant-form-item-control-input-content {
            width: 100%;
            @include setFlex($direction: column);

            &:last-child {
                margin: auto;
                text-align: center;

                button {
                    width: 100%;
                    margin-bottom: 2rem;
                }
            }
        }

        button {
            color: #fff;
            background: $main-color;
        }

        .submit-message {
            width: 100%;
            @include setFlex($align: center, $justify: center);

            a {
                font-weight: 600;
                color: $main-color;
                margin-left: .25rem;
            }
        }

        .ant-form-item-control-input {
            position: relative;

            img {
                position: absolute;
                right: 1rem;
                z-index: 1000;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .ant-input-affix-wrapper {
            display: flex;
            align-items: center;

            &::before {
                display: none;
            }

            &>input.ant-input {
                padding-left: 0;
            }
        }
    }

}

// antd modal
.password-changed-modal {
    .ant-modal-footer {
        .ant-btn {
            &:not(:last-child) {
                display: none;
            }
        }

        a {
            color: #fff;
            font-weight: 600;
            width: 150px;
            height: 40px;
            line-height: 40px;
            border-radius: $border-radius;
            background-color: $success-bg !important;
        }
    }
}
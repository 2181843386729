@import './PrivacyPolicy.scss';

@import '../../../shared/styles/index.scss';

.register {
    height: 90vh;
    display: grid;
    overflow: auto;
    padding: 1rem;

    // padding: 2rem;
    .ant-col.ant-form-item-control {
        width: 100%;
    }

    label {
        @include setFlex($align: center);

        .ant-checkbox+span {
            @include setFlex($align: center, $justify: center);
        }
    }

    .register-container {
        background-color: #fff;
        padding: 1rem;
        border-radius: $border-radius;
        // width: 600px;
        max-width: 90%;
        min-height: 640px;
        box-shadow: $box-shadow;
        margin: auto;
    }

    .switch-box {
        margin: 1rem 0;
        background-color: #F8F8F8;
        border: 1px solid #E2E2E2;
        border-radius: $border-radius;
        // white-space: nowrap;

        .switch-options {
            @include setFlex($justify: space-between, $align: center);

            .option {
                flex: .5;
                padding: .6rem 1rem;
                font-weight: 600;
                text-align: center;
                border-radius: $border-radius;
                cursor: pointer;
                transition: all .2s linear;

                &.active {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }

    form {
        .ant-row.ant-form-item {
            @include setFlex($direction: column);
            margin-bottom: .5rem;

            label {
                font-size: .8rem;
                font-weight: 600;
            }

            .ant-form-item-control-input-content {
                text-align: start;
            }

            .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-sm-16 {
                width: 100%;
                max-width: 100%;
            }

            .ant-col.ant-form-item-control.ant-col-xs-24.ant-col-xs-offset-0.ant-col-sm-16.ant-col-sm-offset-8 {
                margin: 0;

                button {
                    width: 100%;
                    background: $main-color;
                    // padding: 1.2rem;
                    display: grid;
                    place-items: center;
                    height: 45px;

                    &.link {
                        color: $main-color;
                        border: none;
                        background-color: transparent;
                    }
                }
            }

            .ant-checkbox-wrapper {
                text-align: start;

                label {
                    // white-space: nowrap !important;
                    display: flex;
                    align-items: center;
                    max-width: 50%
                }

                span {
                    // white-space: nowrap;

                    &.nowrap {
                        // white-space: nowrap;
                    }
                }
            }

            p {
                font-weight: 600;
                width: 100%;
                text-align: center;
            }

            a {
                color: $main-color;
                font-weight: 600;
            }
        }

        .ant-space.ant-space-vertical {
            width: 100%;
        }

        .info-icon {
            position: relative;
            top: 2rem;
            right: -49%;
            opacity: .5;
        }
    }

    .password-rules-container {
        position: relative;
    }

    .password-rules {
        position: absolute;
        top: 2.5rem;
        right: 1rem;
        z-index: 2;
        font-size: .6rem;
        padding: .5rem 1rem;
        background: #fff;
        border-radius: $border-radius;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
        width: fit-content;

        &.confirm-modal {
            top: 10rem;
        }


        ul {
            li {
                position: relative;

                &::before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: grey;
                    position: absolute;
                    left: -8px;
                    opacity: .5;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

}

// antd modal
.register-success {
    .ant-modal-footer {
        .ant-btn {
            &:not(:last-child) {
                display: none;
            }
        }

        .ant-btn.ant-btn-primary {
            background-color: $success-bg !important;
        }
    }
}
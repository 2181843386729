@import '../../../shared/styles/index.scss';

.recent__transactions {
    display: flex;
    flex-direction: column;
    flex: .5;
    padding: .75rem;
    height: 180px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    background-color: #fff;

    .transactions__header {
        h3 {
            color: $main-color;
            text-align: start;
            font-weight: 700;
        }
    }

    .transactions__content {
        margin-top: .3rem;

        .transactions__grid {
            .row {
                padding: .25rem .8rem;
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .col {
                    position: relative;
                    @include setFlex($direction: column);

                    &:not(:last-child) {
                        margin-bottom: .5rem;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        left: -.9rem;
                        top: .4rem;
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background: $grey-bg;
                        opacity: .3;
                    }

                    span {
                        font-weight: 600;

                        &:first-child {
                            font-size: .8rem;
                            opacity: .8;
                        }


                        &:last-child {
                            font-weight: 800;
                        }
                    }
                }
            }
        }
    }
}
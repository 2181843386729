@import '../../shared/styles/index.scss';

.main-container {
    @include setFlex();

    .main {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // overflow-x: hidden;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
  font-size: 16px;
  width: 100%;
  // overflow-x: hidden;

  @media (max-width: 850px) {
    background-color: #fff;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.ant-spinner {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.ant-layout {

  @media (max-width: 850px) {
    background-color: #fff;
    height: 100% !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-weight: 300;
}

/* Buttons */

.ant-btn.primary-button {
  height: auto;
  min-width: 130px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 20px;
  border: 0;
  background-color: #2058E5;
  border-radius: 4px;

  &:disabled {
    background: #f5f5f5;
  }

  &:hover {
    opacity: .8;
  }

  @media (max-width: 850px) {
    min-width: 98px;
  }
}

.ant-btn.secondary-button {
  color: #2058E5;
  height: auto;
  min-width: 130px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 11px 19px;
  border: 1px solid #2058E5;
  background-color: transparent;
  border-radius: 4px;

  &:hover {
    opacity: .8;
  }

  @media (max-width: 850px) {
    min-width: 98px;
  }
}

/* End Buttons */


/* Cards */

.ant-card {
  font-size: 16px;
}

/* End Cards */

/* Input */

.ant-input {
  font-size: 16px;
  line-height: 24px;
  padding: 7px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;

  &:disabled {
    border: 1px solid rgb(217, 217, 217);
  }
}

.ant-input-affix-wrapper {
  font-size: 16px;
  line-height: 24px;
  padding: 7px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;

  &:disabled {
    border: 1px solid rgb(217, 217, 217);
  }

  &>input.ant-input {
    padding-left: 10px;
  }
}

.ant-form-item-label>label {
  height: 40px;
  font-size: 16px;

  @media (max-width: 850px) {
    height: 30px;
    padding-bottom: 0;
  }
}

.ant-form-large .ant-form-item-label>label {

  @media (max-width: 850px) {
    height: 30px;
  }
}

.ant-form-item .ant-form-item-label {

  @media (max-width: 850px) {
    padding: 0;
  }

  @media (max-width: 575px) {
    padding: 0;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-bottom: 10px;
}

/* End Input */

/* Select */

.ant-select {
  width: 100%;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;
  height: auto;
}

.ant-select-selector {
  font-size: 16px;
  line-height: 24px;
  padding: 4px 10px;
  border: 1px solid #dce6f7;
  border-radius: 4px;
  height: auto;
}

/* End Select */

/* Phone Input */

.react-tel-input {

  .form-control {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #dce6f7;

    &:disabled {
      border: 1px solid #d9d9d9;
      background-color: #f5f5f5;

      &+.flag-dropdown {
        border: 1px solid #d9d9d9;
        background-color: #f5f5f5;
      }
    }
  }

  .flag-dropdown {
    background-color: #fff;
    border: 1px solid #dce6f7;
  }
}

/* End Phone Input */

/* Datepicker */

.ant-picker {
  width: 100%;
  border-radius: 4px;
  padding: 8px 11px;
}

/* End Datepicker */

/* Dropdown */

.ant-dropdown {
  font-size: 16px;
}

.ant-dropdown-menu-item {
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: #fff;
    background-color: #2058E5;

    &>a {
      color: #fff;
    }
  }

  &>a {
    padding: 9px 20px;
  }
}

/* End Dropdown */

/* Table */

.ant-table {
  font-size: 16px;
}

/* End Table */

/* Upload */

.ant-upload.ant-upload-drag .ant-upload {
  padding: 43px 0;
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  text-align: left;
}

/* End Upload */

/* Breadcrumb */

.ant-breadcrumb {
  text-align: left;
  margin: 20px 0;
}

/* End Breadcrumb */
@import '../../../shared/styles/index.scss';

.wallet-overview {
    display: flex;
    flex-direction: column;
    flex: .5;
    padding: .75rem;
    height: 180px;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    background-color: #fff;

    .wallet-header {
        text-align: start;


        .add-wallet-warning {
            @include setFlex($align: flex-start);
            font-size: .8rem;
            padding: .5rem 1rem;
            margin: .75rem 0;
            text-align: start;
            border-radius: $border-radius;
            color: $warning-color;
            background: $warning-bg;
            border: $warning-border;

            &.responsive-sm {
                font-size: .6rem;
                font-weight: 600;
            }

            p {
                margin: 0;
                font-weight: 500;
                margin-left: .35rem;
                margin-top: -.2rem;
            }
        }
    }

    .wallet-balance {
        flex: 1;
        margin: 1.2rem 0;
        @include setFlex($justify: flex-start, $direction: column);

        h6 {
            color: $grey-bg;
            font-size: .9rem;
            font-weight: 600;
        }

        span {
            font-weight: 900;
        }
    }

    .wallet-stats {
        @include setFlex($align: center);

        &>span {
            font-weight: 600;
            color: #505050;
            font-size: .75rem;
        }

        .percentage {
            span {
                color: $green-color;
                margin: 0 .5rem;
                font-size: .8rem;
                font-weight: 600;
            }
        }
    }
}
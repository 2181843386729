@import '../../../shared/styles/index.scss';

.mint__form {
    .form__conclusion {
        @include setFlex();
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: 1px solid #E3E3E3;

        &.responsive-md {
            .form__col {
                height: 90px;

                span {
                    font-size: .75rem !important;
                }
            }
        }

        .form__col {
            width: 50%;
            display: grid;
            grid-template-rows: repeat(2, 1fr);

            &>div {
                text-align: start;
            }

            .conclusion__rate {
                &>span {
                    font-weight: 600;
                    font-size: .8rem;
                    margin-bottom: .5rem;
                    display: inline-block;
                }

                .rate__content {
                    border: 1px solid $main-color;
                    color: $main-color;
                    border-radius: $border-radius;
                    padding: .4rem .4rem .4rem .8rem;
                    font-weight: 600;
                    font-size: .8rem;
                    flex: .5;
                    text-align: start;
                    background-color: #0054B80D;
                }

                span {
                    &:not(:last-child) {
                        margin-right: .5rem;
                    }
                }
            }

            .tranfer-type {
                margin-top: .5rem;
                position: relative;

                @include setFlex($direction: column);

                span {
                    font-weight: 600;
                    font-size: .8rem;
                    margin-bottom: .5rem;
                    display: inline-block;
                }

                &>div {
                    width: 100%;
                    @include setFlex($align: center, $justify: space-between);

                    &>div {
                        cursor: pointer;
                        flex: .5;

                        &:first-child {
                            margin-right: .5rem;
                            background-color: #0054B80D;
                        }
                    }
                }

                .type {
                    padding: .5rem .5rem .5rem .8rem;
                    background-color: #F8F8F8;
                    border: 1px solid #E1E1E1;
                    border-radius: $border-radius;
                    display: grid;
                    text-align: center;

                    img {
                        width: 10px;
                        object-fit: contain;
                    }

                    &.selected {
                        position: relative;
                        border: 1px solid $main-color;
                        font-size: .8rem;
                        text-align: center;
                        color: $main-color;
                    }

                    span {
                        margin: 0;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.kyb {
    .kyb-form {
        .kyb-account-name {}

        .kyb-account-name-text {
            text-align: start;
            margin: 1.5rem 0;
        }
    }

}
@import '../../../shared/styles/index.scss';

.transactions__grid {
    .transactions__header.transactions__row {
        background-color: $main-bg;

        ul {
            li {
                font-size: .8rem;
                font-weight: 600;
                color: #000;
            }
        }
    }
}

.transactions__row {
    padding: .5rem 0 .5rem .5rem;
    border-bottom: 1px solid $light-grey;
    box-shadow: $box-shadow;
    background-color: #fff;

    ul {
        display: grid;
        margin-bottom: 0;
        margin: 0;
        grid-template-columns: repeat(15, 1fr);

        li {
            font-weight: 900;
            text-align: end;
            padding: .5rem;

            &:nth-child(5) {
                grid-column: 5/7;
            }

            &:nth-child(6) {
                grid-column: 7/9;
            }

            &:not(.transactions__header ul li) {
                font-size: .75rem;
                font-weight: 500;
                color: $black-color;
            }

            &:not(:last-child) {
                @include setFlex($align: center, $justify: flex-start);
            }

            &.transaction__name {
                @include setFlex($direction: column);

                span {
                    &:last-child {
                        font-weight: normal;
                    }
                }
            }

            &.transaction__actions {
                button {
                    margin-right: .5rem;
                    padding: .25rem 1.2rem;
                    font-weight: 600;
                    border-radius: $border-radius;
                    background: $main-color;

                    &:first-child {
                        color: #fff;
                    }

                    &:last-child {
                        color: $main-color;
                        background: transparent;
                        border: 1px solid $main-color;
                    }
                }
            }
        }
    }
}
.start-page-header {
  margin-top: 70px;
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  display: block;
  color: black;

  @media (max-width: 850px) {
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
    margin: 109px 0 18px;
  }
}

.start-sub-header {
  font-weight: 400;
  font-size: 36px;
  line-height: 50px;
  color: black;

  @media (max-width: 850px) {
    font-size: 20px;
    line-height: 23px;
  }
}

.ant-btn.start-btn {
  width: 374px;
  height: 88px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 6px;
  margin-bottom: 32px;
  background-color: #fff;
  border: 0;
  color: #2058E5;

  &:hover {
    background-color: #fff;
    color: #2058E5;
    opacity: .7;
  }

  @media (max-width: 850px) {
    width: 102px;
    height: auto;
    font-weight: 600;
    background-color: #fff;
    color: #2058E5;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px;
    border-radius: 4px;
    margin: 0 16px;

    &:hover {
      background-color: #fff;
      color: #2058E5;
    }
  }
}

.start-btn-wrap {
  margin-top: 159px;

  @media (max-width: 850px) {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-col-24 {
      flex: 0 0 auto;
    }
  }
}

.start-wrap {
  background: url("/assets/img/bg/start-bg.jpeg") center top no-repeat;
  background-size: cover;
  padding: 0 16px;

  @media (max-width: 850px) {
  }
}

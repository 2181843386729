.personal-wrap {
  margin-top: 120px;
  height: 100%;

  @media (max-width: 850px) {
    padding: 0 16px;
    margin-top: 58px;
  }

  .personal-header-wrap {

    @media (max-width: 850px) {
      text-align: left;
      margin-bottom: 54px;
    }
  }

  .personal-header {
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    display: block;

    @media (max-width: 850px) {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 17px;
      letter-spacing: -0.02em;
      color: #2058E5;
    }
  }

  .personal-header-desc {

    @media (max-width: 850px) {
      font-size: 16px;
      line-height: 24px;
      color: #7E838D;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {

    @media (max-width: 850px) {
      display: none;
    }
  }

  .ant-divider-inner-text {

    @media (max-width: 850px) {
      padding: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }
  }

  .ant-form.personal-form-wrap .ant-form-item {

    @media (max-width: 850px) {
      flex-wrap: wrap;
    }
  }

  .ant-form-item-label {

    @media (max-width: 850px) {
      text-align: left;
    }
  }

  .ant-col-14 {

    @media (max-width: 850px) {
      max-width: 100%;
    }
  }
}

.ant-form.personal-form-wrap {

  .ant-form-item:last-child {
    margin-bottom: 24px;
  }

  .ant-col-10 {
    padding: 0 4.33333333%;
    flex: 0 0 50%;
    max-width: 50%;

    @media (max-width: 850px) {
      padding: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .personal-btn-wrap {
    padding-right: 4.33333333%;

    @media (max-width: 850px) {
      padding-right: 0;
    }
  }

  .ant-form-item {
    flex-wrap: nowrap;
  }
}
